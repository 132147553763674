import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild, ElementRef, ViewChildren, QueryList, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../services/layout.service';
import { Subscription } from 'rxjs';
import { ConfigService } from '../../services/config.service';
import { Router } from '@angular/router';
import { UserFavoriteMenu } from 'app/models/entities/user-favorite-menu';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { AuthPage } from 'app/models/entities/auth-page';
import { AuthService } from 'app/services/alfaHasar/auth.service';
import { SpSelectAuthorizedMenus } from 'app/models/stored-porcedures/sp-select-authorized-menu';
import { TranslateConfigService } from 'app/services/alfaHasar/translate-config.service';
import { loadMessages, locale } from 'devextreme/localization';
import * as trMessages from 'tr.json';
import { CustomizerService } from 'app/services/customizer.service';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  currentLang = "en";
  selectedLanguageText = "English";
  selectedLanguageFlag = "./assets/img/flags/us.png";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  logoUrl = 'assets/img/logo.png';
  menuPosition = 'Side';
  isSmallScreen = false;
  protected innerWidth: any;
  searchOpenClass = "";
  transparentBGClass = "";
  hideSidebar: boolean = true;
  public isCollapsed = true;
  layoutSub: Subscription;
  configSub: Subscription;
  userName: string;
  userRoleName: string;
  companyName: string;
  userFavoriteMenus: UserFavoriteMenu[] = [];
  userFavoriteMenu: UserFavoriteMenu;
  authPagesDetails: AuthPage[] = [];
  loginUserId: number;
  loginUserCompanyId: number;
  notUserPageAsigment: string = "Sayfa ataması yapılmamış!"

  @ViewChild('search') searchElement: ElementRef;
  @ViewChildren('searchResults') searchResults: QueryList<any>;

  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  @Output()
  seachTextEmpty = new EventEmitter<boolean>();



  public config: any = {};
  allPages: SpSelectAuthorizedMenus[];
  themeName: string;
  menuString: string = "Dosya İşlemleri";
  moduleSting: string = "Dosya İşlemleri";
  hasDefinition: boolean = false;

  constructor(public translate: TranslateService,
    private layoutService: LayoutService,
    private router: Router,
    private autService: AuthService,
    private configService: ConfigService,
    private cd: ChangeDetectorRef,
    private toastrService: ToastrService,
    private location: Location,
    private translateConfigService: TranslateConfigService,
    private customizerService: CustomizerService,


  ) {
    this.toastrService.toastrConfig.preventDuplicates = true;
    translate.use("tr");

    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;

    this.layoutSub = layoutService.toggleSidebar$.subscribe(
      isShow => {
        this.hideSidebar = !isShow;
      });

    this.loginUserId = this.autService.getLoginUserId();
    this.userName = autService.getLoginUserName().toLocaleUpperCase();
    locale("tr");
    this.allPages = this.autService.getAuthPages();
    this.themeName = this.configService.getThemeName();
  }

  ngOnInit() {
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    }
    else {
      this.isSmallScreen = false;
    }

    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     // URL değiştiğinde yapılacak işlemler buraya gelecek
    //     this.handleUrlChange();
    //   }
    // });
  }

  handleUrlChange() {
    this.cd.markForCheck();
    let foundUrl = this.router.url.split('/', 3).join('/');
    let menuDto = this.allPages.find(s => s.url.includes(foundUrl));
    if (menuDto != undefined) {
      this.menuString = menuDto.descr;
      this.moduleSting = menuDto.moduleName;
      if (menuDto.parentId.toString().charAt(2) != "0" && menuDto.parentId != 0) {
        this.hasDefinition = true
      } else {
        this.hasDefinition = false
      }
      this.cd.detectChanges();
    }
  }


  ngAfterViewInit() {
    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      this.cd.markForCheck();
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cd.markForCheck();
    })
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }


  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    }
    else {
      this.isSmallScreen = false;
    }
  }

  loadLayout() {
    this.menuPosition = this.config.layout.menuPosition || this.menuPosition;
    this.logoUrl = this.config.layout.variant === "Light" ? 'assets/img/logo-dark.png' : 'assets/img/logo.png';
    this.transparentBGClass = this.config.layout.variant === "Transparent" ? this.config.layout.sidebar.backgroundColor : "";
  }

  onSearchKey(event: any) {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.add('first-active-item');
    }
    this.seachTextEmpty.emit(event.target.value === "");
  }

  removeActiveClass() {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.remove('first-active-item');
    }
  }

  onEscEvent() {
    // this.control.setValue("");
    this.searchOpenClass = '';
    this.seachTextEmpty.emit(true);
  }

  onEnter() {
    if (this.searchResults && this.searchResults.length > 0 && this.searchResults.first.url) {
      // this.control.setValue("");
      this.searchOpenClass = '';
      this.router.navigate([this.searchResults.first.url]);
      this.seachTextEmpty.emit(true);
    }
  }

  redirectTo(value) {
    this.router.navigate([value]);
    this.seachTextEmpty.emit(true);
  }


  ChangeLanguage(language: string) {
    this.translateConfigService.changeLanguage(language);
    this.changeLocale(language);
    this.selectedLanguageText = language === 'en' ? "English" : language === 'ru' ? "Русский" : "Türkçe";
    this.selectedLanguageFlag = `./assets/img/flags/${language === 'en' ? 'us' : language === 'ru' ? 'ru' : 'tr'}.png`;
    loadMessages(trMessages);
    locale(language);
    locale(navigator.language);
  }

  changeLocale(data) {
    this.setLocale(data);
    parent.document.location.reload();
  }

  setLocale(locale) {
    localStorage.setItem('locale', locale);
  }

  ToggleClass() {
    this.toggleClass = this.toggleClass === "ft-maximize" ? "ft-minimize" : "ft-maximize";
  }

  toggleSearchOpenClass(display) {
    // this.control.setValue("");
    this.searchOpenClass = display ? 'open' : '';
    if (display) {
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    }
    this.seachTextEmpty.emit(true);
  }

  refresh(): void {
    this.router.navigateByUrl("/refresh", { skipLocationChange: true }).then(() => {
      this.cd.markForCheck();
      this.router.navigate([decodeURI(this.location.path())]);
      this.cd.detectChanges();
    });
  }

  toggleNotificationSidebar() {
    this.layoutService.toggleNotificationSidebar(true);
  }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }



  changeTheme() {
    let theme = localStorage.getItem("dx-theme") ?? null;

    if (theme == null || theme == "light") {
      this.customizerService.switchLayout("dark", true);
    }
    else {
      this.customizerService.switchLayout("light", true);
    }
  }


  refreshApp() {
    window.location.reload();
  }

  logout() {
    this.autService.logout()
  }
}
