import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { LoginModel } from 'app/models/entities/AlfaHasar/loginModel';
import { ObjectResponseModel } from 'app/shared/response-models/object-response-model';
import { User } from 'app/models/entities/AlfaHasar/user';
import { TokenModel } from 'app/models/entities/AlfaHasar/tokenModel';
import { ResponseModel } from 'app/shared/response-models/response-model';
import { ChangePasswordDto } from 'app/models/dtos/change-password-dto';
import { UserDto } from 'app/models/dtos/user-dto';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { ViewUserInformation } from 'app/models/views/view-user-information';
import { MenuDto } from 'app/models/dtos/menu-dto';
import { SpSelectAuthorizedMenus } from 'app/models/stored-porcedures/sp-select-authorized-menu';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  userToken: any;
  decodedToken: any;
  jwtHelper: JwtHelperService = new JwtHelperService();
  TOKEN_KEY = "token";
  constructor(private httpClient: HttpClient, private router: Router,
    private toastrService: ToastrService,
  ) {

  }

  login(loginModel: LoginModel) {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.httpClient.post<ObjectResponseModel<UserDto>>(environment.apiUrl + "User/login", loginModel, { headers: headers })
      .subscribe(result => {
        if (result.success) {
          this.setAuthPages(result.data.pages);
          this.setLoginUserInfoName(result.data.user)
          this.saveToken(result.data.accessToken.token);
          this.userToken = result.data.accessToken.token;
          this.decodedToken = this.jwtHelper.decodeToken(result.data.accessToken.token.toString());
          this.setLoginUserInfo(result.data);
          this.setMenuTrees(result.data.menuDto);
          if (result.data.menuDto[0].data.url == null) {
            this.router.navigate([result.data.menuDto[0].children[0].data.url]);
          } else {
            this.router.navigate([result.data.menuDto[0].data.url]);
          }
        } else {
          this.toastrService.error("Kullanıcı adı veya şifre hatalı!!!", "Hata !!!");
        }
      });
  }


  saveToken(token) {
    localStorage.setItem(this.TOKEN_KEY, token);
  }


  isAuthenticated(): boolean {
    if (parseInt(sessionStorage.getItem('loginUserId')) == 0 || sessionStorage.getItem('loginUserId') == null || sessionStorage.getItem('loginUserId') == "") return false;
    return true;
  }

  setToken(tokenModel: TokenModel) {
    sessionStorage.setItem("token", tokenModel.token);
  }


  setLoginUserInfo(userDto: UserDto) {
    sessionStorage.setItem('loginUserId', userDto.user.id.toString());
  }

  setLoginUserInfoName(user: User) {
    sessionStorage.setItem('loginUserName', user.firstName + " " + user.lastName);
  }

  getLoginUserCompany() {
    return sessionStorage.getItem('loginUserCompanyName');
  }

  getLoginUserName() {
    return sessionStorage.getItem('loginUserName');
  }
  getLoginUserCompanyCode() {
    return sessionStorage.getItem('loginUserCompanyCode');
  }

  getLoginUserId() {
    return parseInt(sessionStorage.getItem('loginUserId'));
  }

  getLoginUserCompanyId() {
    return parseInt(sessionStorage.getItem('loginUserCompanyId'));
  }

  getLoginUserRoleId() {
    return parseInt(sessionStorage.getItem('loginUserRoleId'));
  }

  changePassword(updatedPassword: ChangePasswordDto) {
    return this.httpClient.post<ResponseModel>(environment.apiUrl + "User/changePassword", updatedPassword);
  }
  memberSave(updatedPassword: ChangePasswordDto) {
    return this.httpClient.post<ResponseModel>(environment.apiUrl + "User/memberSave", updatedPassword);
  }
  getMemberId(memberId: number): Observable<ObjectResponseModel<User>> {
    return this.httpClient.get<ObjectResponseModel<User>>(environment.apiUrl + "User/getMemberId?memberId=" + memberId);
  }

  register(registeredUser: ViewUserInformation) {
    return this.httpClient.post<ObjectResponseModel<ViewUserInformation>>(environment.apiUrl + "Auth/register", registeredUser);
  }

  logout() {
    localStorage.removeItem(this.TOKEN_KEY)
    localStorage.clear()
    this.router.navigateByUrl('login');
  }


  loggedIn() {
    return this.jwtHelper.isTokenExpired(this.TOKEN_KEY)
  }

  get token() {
    return localStorage.getItem(this.TOKEN_KEY);
  }


  setAuthPages(authPages: SpSelectAuthorizedMenus[]) {
    let AuthPages = authPages.filter(s => s.authPageId != 0);
    return localStorage.setItem("authPages", JSON.stringify(AuthPages));
  }

  getAuthPages() {
    return JSON.parse(localStorage.getItem("authPages")) as SpSelectAuthorizedMenus[];
  }
  setMenuTrees(menuDtos: MenuDto[]) {
    return localStorage.setItem("menuDtos", JSON.stringify(menuDtos));
  }

  getMenuTrees() {
    return JSON.parse(localStorage.getItem("menuDtos")) as MenuDto[];
  }

  getCurrentUserId() {
    return this.jwtHelper.decodeToken(this.token).nameid
  }

  // login(loginModel: LoginModel): Observable<ObjectResponseModel<User>> {
  //   return this.httpClient.post<ObjectResponseModel<User>>(environment.apiUrl + "Auth/login", loginModel);
  // }

  // isAuthenticated(): boolean {
  //   if (parseInt(sessionStorage.getItem('loginUserId')) == 0 || sessionStorage.getItem('loginUserId') == null || sessionStorage.getItem('loginUserId') == "") return false;
  //   return true;
  // }

  // setToken(tokenModel: TokenModel) {
  //   sessionStorage.setItem("token", tokenModel.token);
  // }


  // setLoginUserInfo(user: User) {
  //   sessionStorage.setItem('loginUserId', user.userId.toString());
  // }

  // getLoginUserId() {
  //   return parseInt(sessionStorage.getItem('loginUserId'));
  // }

  // getLoginUserCompanyId() {
  //   return parseInt(sessionStorage.getItem('loginUserCompanyId'));
  // }

  // changePassword(updatedPassword: ChangePasswordDto) {
  //   return this.httpClient.post<ResponseModel>(environment.apiUrl + "Auth/changePassword", updatedPassword);
  // }

  // logout() {
  //   sessionStorage.clear();
  //   sessionStorage.removeItem('loginUserId');
  //   this.router.navigateByUrl('login');
  // }

}
