<!-- $menuItems -->

<!-- Sidebar Header starts -->
<div class="sidebar-header" *ngIf="config?.layout.menuPosition === 'Side'">
  <div class="logo clearfix">
    <a (click)="navigateDashboard()" class="logo-text float-left">
      <div class="logo-img">
        <img [src]="logoUrl" alt="Apex logo" />
      </div>
      <span class="text align-middle">TÜGVA</span>
    </a>
    <a class="nav-toggle d-none d-lg-none d-xl-block" id="sidebarToggle" href="javascript:;" (click)="toggleSidebar()">
      <i #toggleIcon [ngClass]="{
        'ft-toggle-left': config.layout.sidebar.collapsed,
        'ft-toggle-right': !config.layout.sidebar.collapsed
      }" class="toggle-icon"></i>
    </a>
    <a class="nav-close d-block d-lg-block d-xl-none" id="sidebarClose" href="javascript:;" (click)="CloseSidebar()">
      <i class="ft-x"></i>
    </a>
  </div>
</div>
<!-- Sidebar Header Ends -->

<!-- Sidebar Content starts -->
<div class="sidebar-content main-menu-content" [perfectScrollbar] [disabled]="!perfectScrollbarEnable">
  <div class="nav-container" id="title">
    <ul class="navigation" appSidebarDropdown>
      <!-- First level menu -->
      <dx-select-box class="m-2" [dataSource]="menus" displayExpr="descr" valueExpr="menuId" [searchEnabled]="true"
        showDataBeforeSearch="false" minSearchLength="1" searchExprOption="descr"
        (onValueChanged)="routeToLink($event)"></dx-select-box>
      <li appSidebarlink *ngFor="let menuItem of $menuItems" [parent]="menuItem.data.descr" [path]="menuItem.data.url"
        level="{{level + 1}}" [hasSub]="menuItem.data.className.includes('has-sub') ? true: false"
        [ngClass]="{'has-sub': menuItem.data.className.includes('has-sub') ? true: false}"
        [routerLinkActive]="menuItem.children.length != 0 ? 'open' : 'active'"
        [routerLinkActiveOptions]="{exact: false}">
        <a appSidebarAnchorToggle *ngIf="menuItem.children.length > 0">
          <i [ngClass]="[menuItem.data.imageClass]"></i>
          <span class="menu-title">{{menuItem.data.descr | translate }}</span>
        </a>
        <a appSidebarAnchorToggle routerLink="{{menuItem.data.url}}" *ngIf="menuItem.children.length === 0">
          <i [ngClass]="[menuItem.data.imageClass]"></i>
          <span class="menu-title">{{menuItem.data.descr | translate }}</span>
        </a>

        <!-- Second level menu -->
        <ul appSidebarDropdown class="menu-content" *ngIf="menuItem.children.length > 0 ">
          <li appSidebarlink *ngFor="let menuSubItem of menuItem.children" [parent]="menuItem.data.descr"
            [hasSub]="menuSubItem.data.className.includes('has-sub') ? true: false" [path]="menuSubItem.data.url"
            level="{{level + 2}}" [ngClass]="{'has-sub': menuSubItem.data.className.includes('has-sub') ? true: false}"
            [routerLinkActive]="menuSubItem.children.length != 0 ? 'open' : 'active'">
            <a appSidebarAnchorToggle *ngIf="menuSubItem.children.length > 0">
              <i [ngClass]="[menuSubItem.data.imageClass]"></i>
              <span class="menu-title">{{menuSubItem.data.descr | translate }}</span>
            </a>
            <a appSidebarAnchorToggle routerLink="{{menuSubItem.data.url}}" *ngIf="menuSubItem.children.length === 0">
              <i [ngClass]="[menuSubItem.data.imageClass]"></i>
              <span class="menu-title">{{menuSubItem.data.descr | translate }}</span>
            </a>

            <!-- Third level menu -->
            <ul appSidebarDropdown class="menu-content" *ngIf="menuSubItem.children.length > 0">
              <li appSidebarlink *ngFor="let menuSubsubItem of menuSubItem.children" [parent]="menuSubItem.data.descr"
                [hasSub]="false" [path]="menuSubsubItem.data.url" level="{{level + 3}}" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
                [ngClass]="config.layout.menuPosition === 'Side' ? menuSubsubItem.data.className : ''">
                <a appSidebarAnchorToggle routerLink="{{menuSubsubItem.data.url}}">
                  <i [ngClass]="[menuSubsubItem.data.imageClass]"></i>
                  <span class="menu-title">{{menuSubsubItem.data.descr | translate }}</span>
                </a>

              </li>
            </ul>

          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
<!-- Sidebar Content Ends -->
