import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthPage } from 'app/models/entities/auth-page';
import { ListResponseModel } from 'app/shared/response-models/list-response-model';
import { ObjectResponseModel } from 'app/shared/response-models/object-response-model';
import { ResponseModel } from 'app/shared/response-models/response-model';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthPageService {
  constructor( private httpClient: HttpClient ) { }
    
  getList(): Observable<ListResponseModel<AuthPage>> {
    return this.httpClient.get<ListResponseModel<AuthPage>>(environment.apiUrl + "AuthPages/getList");
  }

  getById(id: number): Observable<ObjectResponseModel<AuthPage>> {
    return this.httpClient.get<ObjectResponseModel<AuthPage>>(environment.apiUrl + "AuthPages/getById?Id=" + id);
  }

  getByMenuId(menuId: number): Observable<ObjectResponseModel<AuthPage>> {
    return this.httpClient.get<ObjectResponseModel<AuthPage>>(environment.apiUrl + "AuthPages/getByMenuId?menuId=" + menuId);
  }

  add(authPage: AuthPage) {
    return this.httpClient.post<ResponseModel>(environment.apiUrl + "AuthPages/add", authPage);
  }

  update(authPage: AuthPage) {
    return this.httpClient.post<ResponseModel>(environment.apiUrl + "AuthPages/update", authPage);
  }

  delete(id: number) {
    return this.httpClient.post<ResponseModel>(environment.apiUrl + "AuthPages/delete?Id=" + id, "");
  }
}
