import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from './alfaHasar/user.service'
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { LoginModel } from '../models/entities/loginModel';
import { TokenModel } from '../models/entities/tokenModel';
import { ResponseModel } from '../shared/response-models/response-model';
import { ChangePasswordDto } from '../models/dtos/change-password-dto';
import { ObjectResponseModel } from '../shared/response-models/object-response-model';
import { ViewUserInformation } from '../models/views/view-user-information';
import { Company } from 'app/models/entities/company';
import { UserDto } from 'app/models/dtos/user-dto';
import { ToastrService } from 'ngx-toastr';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthPage } from 'app/models/entities/auth-page';
import { AuthPageService } from './auth-page.service';
import { SpSelectAuthorizedMenus } from 'app/models/stored-porcedures/sp-select-authorized-menu';
import { UserFavoriteMenu } from 'app/models/entities/user-favorite-menu';
import { MenuDto } from 'app/models/dtos/menu-dto';
import { User } from 'app/models/entities/AlfaHasar/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userToken: any;
  decodedToken: any;
  jwtHelper: JwtHelperService = new JwtHelperService();
  TOKEN_KEY = "token";


  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private userService: UserService,
    private toastrService: ToastrService,
    private authPageService: AuthPageService
  ) {

  }



  login(loginModel: LoginModel) {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.httpClient
      .post<ObjectResponseModel<UserDto>>(environment.apiUrl + "User/login", loginModel, { headers: headers })
      .subscribe(result => {
        if (result.success) {
          this.setAuthPages(result.data.pages)
          this.setLoginUserInfoName(result.data.user)
          this.saveToken(result.data.accessToken.token);
          this.userToken = result.data.accessToken.token;
          this.decodedToken = this.jwtHelper.decodeToken(result.data.accessToken.token.toString());
          this.setLoginUserInfo(result.data);
          this.setMenuTrees(result.data.menuDto);
          if (result.data.menuDto[0].data.url == null) {
            this.router.navigate([result.data.menuDto[0].children[0].data.url]);
          } else {
            this.router.navigate([result.data.menuDto[0].data.url]);
          }
        } else {
          this.toastrService.error("Kullanıcı adı veya şifre hatalı!!!", "Hata !!!");
        }
      }, er => {
        this.toastrService.error(er.error.message);
      });
  }


  saveToken(token) {
    localStorage.setItem(this.TOKEN_KEY, token);
  }


  isAuthenticated(): boolean {
    if (parseInt(sessionStorage.getItem('loginUserId')) == 0 || sessionStorage.getItem('loginUserId') == null || sessionStorage.getItem('loginUserId') == "") return false;
    return true;
  }

  setToken(tokenModel: TokenModel) {
    sessionStorage.setItem("token", tokenModel.token);
  }


  setLoginUserInfo(userDto: UserDto) {
    sessionStorage.setItem('loginUserId', userDto.user.id.toString());
    sessionStorage.setItem('loginUserRoleId', userDto.user.userRoleId.toString());
  }


  getLoginUserId() {
    return parseInt(sessionStorage.getItem('loginUserId'));
  }

  getLoginUserRoleId() {
    return parseInt(sessionStorage.getItem('loginUserRoleId'));
  }

  changePassword(updatedPassword: ChangePasswordDto) {
    return this.httpClient.post<ResponseModel>(environment.apiUrl + "Auth/changePassword", updatedPassword);
  }

  register(registeredUser: ViewUserInformation) {
    return this.httpClient.post<ObjectResponseModel<ViewUserInformation>>(environment.apiUrl + "Auth/register", registeredUser);
  }

  logout() {
    localStorage.removeItem(this.TOKEN_KEY)
    sessionStorage.clear();
    sessionStorage.removeItem('loginUserId');
    sessionStorage.removeItem('loginUserCompanyId');
    sessionStorage.removeItem('loginUserRoleId');
    this.router.navigateByUrl('login')
  }


  loggedIn() {
    return this.jwtHelper.isTokenExpired(this.TOKEN_KEY)
  }

  get token() {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  setLoginUserInfoName(user: User) {
    sessionStorage.setItem('loginUserName', user.firstName + " " + user.lastName);
  }

  getLoginUserName() {
    return sessionStorage.getItem('loginUserName');
  }

  getCurrentUserId() {
    return this.jwtHelper.decodeToken(this.token).nameid
  }

  setAuthPages(authPages: SpSelectAuthorizedMenus[]) {
    let AuthPages = authPages.filter(s => s.authPageId != 0);
    return localStorage.setItem("authPages", JSON.stringify(AuthPages));
  }

  getAuthPages() {
    return JSON.parse(localStorage.getItem("authPages")) as SpSelectAuthorizedMenus[];
  }
  setMenuTrees(menuDtos: MenuDto[]) {
    return localStorage.setItem("menuDtos", JSON.stringify(menuDtos));
  }

  getMenuTrees() {
    return JSON.parse(localStorage.getItem("menuDtos")) as MenuDto[];
  }


  setUserInformation(userInformation: ViewUserInformation) {
    return localStorage.setItem("userInformation", JSON.stringify(userInformation));
  }

  getUserInformation() {
    return JSON.parse(localStorage.getItem("userInformation")) as ViewUserInformation;
  }


  SetUserFavoriteMenu(userFavoriteMenu: UserFavoriteMenu[]) {
    return localStorage.setItem("userFavoriteMenu", JSON.stringify(userFavoriteMenu));
  }

  getUserFavoriteMenu() {
    return JSON.parse(localStorage.getItem("userFavoriteMenu")) as UserFavoriteMenu[];
  }

}
