import { RouteInfo } from '../vertical-menu/vertical-menu.metadata';

export const HROUTES: RouteInfo[] = [
  // {
  //   path: '/pages/mainpage', title: 'Anasayfa', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },
  {
    path: '/product/product-category', title: 'Dosya İşlemleri', icon: 'ft-layers', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  }
  ,
  {
    path: '/product/product-category2', title: 'Bütçe', icon: 'ft-layers', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  }
  ,
  {
    path: '/product/product-category3', title: 'Personel İşlemleri', icon: 'ft-layers', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  }
  ,
  {
    path: '/product/product-category4', title: 'Referans İşlemleri', icon: 'ft-layers', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '', title: 'Tanımlar', icon: 'ft-layers', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/pages/ExecutiveDirectorates', title: 'İcra Müdürlüğü Tanımlama', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/File-Subject', title: 'Dosya Konusu', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/File-Status', title: 'Dosya Durumu', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/File-Type', title: 'Dosya Tipi', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/Reference-Type', title: 'Referans Tipi', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/Worker-Type', title: 'Çalışan Tipi', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/Budget-Type', title: 'Bütçe Tipi', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/Case-Budget-Type', title: 'Dava Bütçe Tipi', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
    ]
  }
];
