import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { PickListModule } from 'primeng/picklist';
import { DialogModule } from 'primeng/dialog';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TabViewModule } from 'primeng/tabview';
import { RatingModule } from 'primeng/rating';
import { PrimeNGConfig } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { PanelModule } from 'primeng/panel';
import { TreeTableModule } from 'primeng/treetable';
import { TreeNode } from 'primeng/api';
import { TreeModule } from 'primeng/tree';
import { TreeSelectModule } from 'primeng/treeselect';
import {ListboxModule} from 'primeng/listbox';
import {ChipsModule} from 'primeng/chips';
import {AutoCompleteModule} from 'primeng/autocomplete';

@NgModule({
  declarations: [],
  imports: [
    TableModule,
    ButtonModule,
    TooltipModule,
    InputTextModule,
    SelectButtonModule,
    InputMaskModule,
    DropdownModule,
    FormsModule,
    InputTextareaModule,
    PickListModule,
    DialogModule,
    DynamicDialogModule,
    ConfirmDialogModule
  ],
  exports: [
    TableModule,
    ButtonModule,
    TooltipModule,
    InputTextModule,
    SelectButtonModule,
    InputMaskModule,
    DropdownModule,
    FormsModule,
    InputTextareaModule,
    PickListModule,
    DialogModule,
    DynamicDialogModule,
    ConfirmDialogModule,
    TabViewModule,
    RatingModule,
    ToggleButtonModule,
    PanelModule,
    TreeTableModule,
    TreeSelectModule,
    TreeModule,
    ListboxModule,
    ChipsModule,
    AutoCompleteModule
  ],
  providers: [
    PrimeNGConfig,
    ConfirmationService,
    DialogService
  ]
})
export class PrimeNgModule { }


