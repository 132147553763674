import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ViewUser } from '../../models/views/view-user';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'environments/environment';
import { ResponseModel } from '../../shared/response-models/response-model';
import { ObjectResponseModel } from '../../shared/response-models/object-response-model';
import { ViewUserInformation } from '../../models/views/view-user-information';
import { ListResponseModel } from '../../shared/response-models/list-response-model';
import { User } from 'app/models/entities/AlfaHasar/user';
import { ChangePasswordDto } from 'app/models/dtos/change-password-dto';
import { GridListDto } from 'app/models/dtos/grid-list-dto';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) { }

  getList(companyId: number): Observable<ListResponseModel<ViewUser>> {
    return this.httpClient.get<ListResponseModel<ViewUser>>(environment.apiUrl + "User/getList?companyId=" + companyId);
  }

  getById(id: number): Observable<ObjectResponseModel<User>> {
    return this.httpClient.get<ObjectResponseModel<User>>(environment.apiUrl + "User/getById?Id=" + id);
  }

  getUserList(companyId: number): Observable<ListResponseModel<User>> {
    return this.httpClient.get<ListResponseModel<User>>(environment.apiUrl + "User/getUserList?companyId=" + companyId);
  }

  getFnUserOfCompanyGroup(companyId: number): Observable<ListResponseModel<ViewUser>> {
    return this.httpClient.get<ListResponseModel<ViewUser>>(environment.apiUrl + "User/getFnUserOfCompanyGroup?companyId=" + companyId);
  }

  getFnUserOfCompanyGroupByActivity(companyId: number, isActive: boolean): Observable<ListResponseModel<ViewUser>> {
    return this.httpClient.get<ListResponseModel<ViewUser>>(environment.apiUrl + "User/getFnUserOfCompanyGroupByActivity?companyId=" + companyId + "&isActive=" + isActive);
  }
  getFnUsersByActive(isActive: boolean): Observable<ListResponseModel<ViewUser>> {
    return this.httpClient.get<ListResponseModel<ViewUser>>(environment.apiUrl + "User/getFnUsersByActive?isActive=" + isActive);
  }
  getUserInformationById(id: number): Observable<ObjectResponseModel<ViewUserInformation>> {
    return this.httpClient.get<ObjectResponseModel<ViewUserInformation>>(environment.apiUrl + "User/getUserInformationById?Id=" + id);
  }

  updateUserInfo(user: ViewUserInformation) {
    return this.httpClient.post<ResponseModel>(environment.apiUrl + "User/updateUserInfo", user);
  }
  update(user: User) {
    return this.httpClient.post<ResponseModel>(environment.apiUrl + "User/update", user);
  }
  delete(id: number) {
    return this.httpClient.post<ResponseModel>(environment.apiUrl + "User/delete?Id=" + id, "");
  }

  updateStatus(id: number, status: boolean) {
    return this.httpClient.post<ResponseModel>(environment.apiUrl + "User/updateStatus?Id=" + id + "&status=" + status, "");
  }

  updateStatusRange(ids: number[], status: number) {
    return this.httpClient.post<ResponseModel>(environment.apiUrl + "User/updateStatusRange? &status=" + status, ids);
  }

  changePassword(updatedPassword: ChangePasswordDto) {
    return this.httpClient.post<ResponseModel>(environment.apiUrl + "User/changePassword", updatedPassword);
  }

  register(registeredUser: ViewUserInformation) {
    return this.httpClient.post<ObjectResponseModel<ViewUserInformation>>(environment.apiUrl + "User/register", registeredUser);
  }
  loadUserGrid(): Observable<ObjectResponseModel<GridListDto<ViewUser>>> {
    return this.httpClient.get<ObjectResponseModel<GridListDto<ViewUser>>>(environment.apiUrl + "User/loadUserGrid");
  }
}
