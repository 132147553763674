<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}" [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}" id="navbarClickEvent">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
        <li class="nav-item mr-2 d-none d-lg-block">
          <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;" appToggleFullscreen
            (click)="ToggleClass()"><i class="{{toggleClass}} font-medium-3"></i></a>
        </li>
        <li class="nav-item mr-2 d-none d-lg-block">
          <!-- <a class="nav-link font-weight-bold text-light"
            [title]="userFavoriteMenus[0]==null?notUserPageAsigment:userFavoriteMenus[0].pageUrl" (click)="leftClick(1)"
            (contextmenu)="rightClick(1)">1</a> -->

          <!-- <button type="button" class="btn btn-primary btn-lg font-weight-bold badge badge-pill"
            [pTooltip]="authPagesDetails[0]==null?notUserPageAsigment:authPagesDetails[0].descr" (click)="leftClick(1)"
            (contextmenu)="rightClick(1)">1</button> -->
        </li>
        <li class="nav-item mr-2 d-none d-lg-block">
          <!-- <a class="nav-link font-weight-bold text-light" (click)="leftClick(2)" (contextmenu)="rightClick(2)"
            [title]="userFavoriteMenus[1]==null?notUserPageAsigment:userFavoriteMenus[1].pageUrl">2</a> -->

          <!-- <button type="button" class="btn btn-primary btn-lg font-weight-bold badge badge-pill"
            [pTooltip]="authPagesDetails[1]==null?notUserPageAsigment:authPagesDetails[1].descr" (click)="leftClick(2)"
            (contextmenu)="rightClick(2)">2</button> -->
        </li>
        <li class="nav-item mr-2 d-none d-lg-block ">
          <!-- <a class="nav-link font-weight-bold text-light" (click)="leftClick(3)" (contextmenu)="rightClick(3)"
            [title]="userFavoriteMenus[2]==null?notUserPageAsigment:userFavoriteMenus[3].pageUrl">3</a> -->
          <!--
          <button type="button" class="btn btn-primary btn-lg font-weight-bold badge badge-pill"
            [pTooltip]="authPagesDetails[2]==null?notUserPageAsigment:authPagesDetails[2].descr" (click)="leftClick(3)"
            (contextmenu)="rightClick(3)">3</button> -->
        </li>
        <li class="nav-item mr-2 d-none d-lg-block ">
          <!-- <a class="nav-link font-weight-bold text-light" (click)="leftClick(4)" (contextmenu)="rightClick(4)"
            [title]="userFavoriteMenus[3]==null?notUserPageAsigment:userFavoriteMenus[3].pageUrl">4</a> -->
          <!-- <button type="button" class="btn btn-primary btn-lg font-weight-bold badge badge-pill"
            [pTooltip]="authPagesDetails[3]==null?notUserPageAsigment:authPagesDetails[3].descr" (click)="leftClick(4)"
            (contextmenu)="rightClick(4)">4</button> -->
        </li>
        <li class="nav-item mr-2 d-none d-lg-block ">
          <!-- <a class="nav-link font-weight-bold text-light" (click)="leftClick(5)" (contextmenu)="rightClick(5)"
            [title]="userFavoriteMenus[4]==null?notUserPageAsigment:userFavoriteMenus[4].pageUrl">5</a> -->
          <!-- <button type="button" class="btn btn-primary btn-lg font-weight-bold badge badge-pill"
            [pTooltip]="authPagesDetails[4]==null?notUserPageAsigment:authPagesDetails[4].descr" (click)="leftClick(5)"
            (contextmenu)="rightClick(5)">5</button> -->
        </li>
        <!-- <li class="nav-item nav-search">
          <a class="nav-link nav-link-search" id="navbar-search" href="javascript:" (click)="toggleSearchOpenClass(true)"><i
              class="ft-search font-medium-3"></i></a>
          <div class="search-input {{searchOpenClass}}">
            <div class="search-input-icon">
              <i class="ft-search font-medium-3"></i>
            </div>
            <input [formControl]="control" #search class="input" type="text" placeholder="Explore Apex..." tabindex="0"
              autofocus data-search="template-search" [appAutocomplete]="autocomplete" (keyup.enter)="onEnter()" (keydown.esc)="onEscEvent()" (keyup)="onSearchKey($event)" />
            <div class="search-input-close" (click)="toggleSearchOpenClass(false)">
              <i class="ft-x font-medium-3"></i>
            </div>

            <app-autocomplete #autocomplete="appAutocomplete">
              <ng-template appAutocompleteContent >
                <ng-container *ngIf="(listItems | filter: control.value) as result">
                  <app-option #searchResults *ngFor="let option of result" [url]="option.url" [value]="option.name" (click)="redirectTo(option.url);" (mouseenter)="removeActiveClass()"  >
                    <div class="d-flex align-items-center justify-content-between w-100">
                      <div class="d-flex align-items-center justify-content-start">
                        <i class="{{option.icon}} mr-2"></i>
                        <span>{{ option.name }}</span>
                      </div>
                    </div>
                  </app-option>
                  <app-option class="no-result" *ngIf="!result.length">No results found.</app-option>
                </ng-container>
              </ng-template>
            </app-autocomplete>
          </div>
        </li> -->
      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo"><a class="logo-text" [routerLink]="['/']" *ngIf="config?.layout.menuPosition === 'Top'">
                  <div class="logo-img"><img class="logo-img" alt="Tulpar logo" [src]="logoUrl"></div><span
                    class="text">TÜGVA İSTANBUL</span>
                </a></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <!-- <li class="dropdown nav-item" placement="bottom-left" display="static" ngbDropdown>
            <a class="nav-link dropdown-toggle dropdown-notification p-0 mt-2" id="drp-notification" href="javascript:;"
              data-toggle="dropdown" ngbDropdownToggle><i class="ft-bell font-medium-3"></i><span
                class="notification badge badge-pill badge-danger">2</span></a>
            <ul ngbDropdownMenu
              class="notification-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden">

              <li class="dropdown-menu-header">
                <div class="dropdown-header d-flex justify-content-between m-0 px-3 py-2 white bg-primary">
                  <div class="d-flex">
                    <i class="ft-bell font-medium-3 d-flex align-items-center mr-2"></i><span
                      class="noti-title">Bildirimler</span>
                  </div>
                  <span class="text-bold-400 cursor-pointer"></span>
                </div>
              </li>
              <li class="scrollable-container" [perfectScrollbar]>
                <a class="d-flex justify-content-between" href="javascript:void(0)">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="mr-3">
                        <img class="avatar" src="assets/img/portrait/small/avatar-s-20.png" alt="avatar" height="45"
                          width="45" />
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Kate Young</span><small class="grey lighten-1 font-italic float-right">5 mins
                          ago</small>
                      </h6>
                      <small class="noti-text">Commented on your photo</small>
                      <h6 class="noti-text font-small-3 m-0">
                        Great Shot John! Really enjoying the composition on this
                        piece.
                      </h6>
                    </div>
                  </div>
                </a>
                <a class="d-flex justify-content-between" href="javascript:void(0)">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="mr-3">
                        <img class="avatar" src="assets/img/portrait/small/avatar-s-11.png" alt="avatar" height="45"
                          width="45" />
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>Andrew Watts</span><small class="grey lighten-1 font-italic float-right">49 mins
                          ago</small>
                      </h6>
                      <small class="noti-text">Liked your album: UI/UX Inspo</small>
                    </div>
                  </div>
                </a>
              </li>
              <li class="dropdown-menu-footer">
              </li>
            </ul>
          </li> -->
          <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown>
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <div class="user d-md-flex d-none mr-2">
                <span class="text-right mt-1">{{userName}}</span>
                <span class="text-right text-muted font-small-3"> TÜGVA İSTANBUL </span>
              </div>
              <div lass="align-self-center">
                <p-avatar icon="pi pi-user" shape="circle" cla
                  [style]="{'background-color': '#6f42c1', 'color': '#ffffff'}"></p-avatar>
              </div>
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2"
              ngbDropdownMenu>

              <a class="dropdown-item" routerLink="/pages/profile">
                <div class="d-flex align-items-center">
                  <i class="ft-edit mr-2"></i><span>Profilini Güncelle</span>
                </div>
              </a>

              <div class="dropdown-divider"></div>
              <a class="dropdown-item" routerLink="/pages/login">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Çıkış</span>
                </div>
              </a>
            </div>
          </li>
          <!-- <li class="nav-item d-none d-lg-block mr-2 mt-1">
            <a class="nav-link notification-sidebar-toggle" (click)="toggleNotificationSidebar();"><i
                class="ft-align-right font-medium-3"></i></a>
          </li> -->
        </ul>
      </div>
    </div>
  </div>





</nav>


